/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';

const imageUploadHandler = (blobInfo, success, failure) => {
  const formData = new FormData();

  formData.append('image', blobInfo.blob());

  if (blobInfo.blob().type.includes('image')) {
    const imageSize = Math.trunc(blobInfo.blob().size / 1000);
    if (imageSize > 3000) {
      failure('Maximum size : 3M');
    }
    axios.post('/campaigns/image/add', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        if (response) {
          const json = JSON.parse(response.data);

          if (!json || typeof json.location !== 'string') {
            failure('Invalid JSON');
            return;
          }

          const location = process.env.REACT_APP_AWS_ROOT + process.env.REACT_APP_AWS_ATTACHMENTS_PATH + json.location;

          success(location);
        }
      })
      .catch(() => {
        failure('Something went wrong, please try again.');
      });
  } else {
    failure('The file is not a image, please try again with an image.');
  }
};

let content = null;
const editorConfig = {
  plugins: [
    'advlist autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste code help wordcount',
  ].join(' '),
  toolbar:
  // eslint-disable-next-line no-multi-str
      'undo redo | formatselect | bold italic backcolor | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | removeformat | help',
  block_formats: 'Paragraph=p;Heading 3=h3',
  height: 350,
  // statusbar: false,
  images_upload_url: 'postAcceptor.php',
  images_upload_handler: imageUploadHandler,
  // body_class: 'editable-field-content',
  // paste_word_valid_elements: 'b,strong,i,em,h1,h2,h3,p,li,ul,ol,a',
  // paste_retain_style_properties: 'none',
  // paste_strip_class_attributes: 'none',
  // paste_remove_styles: true,
};

const renderTinyMCEField = ({ input, meta: { error }, dispatched }) => {
  const [templates, setTemplates] = useState();
  const [touched, setTouched] = useState(false);
  if (typeof templates === 'object' && templates !== null
      && 'content' in templates && templates.content !== null) {
    ({ content } = templates);
    setTemplates(null);
  }

  // const handleEditorChange = (content, editor) => {
  //   return true;
  // };

  return (
    <div className="form__form-group-input-wrap">
      <Editor
        {...input}
        value={
          /* eslint-disable-next-line no-nested-ternary */
          content || (input.value !== '' ? input.value : null)
        }
        // onChange={value => this.onEditorChange(value)}
        apiKey="fkjxk35pbnnm990x5wo3fakejxr9rrt5n9ojws99cpwrg76w"
        onBlur={(event) => { input.onChange(event.target.getContent()); setTouched(true); }}
        // onEditorChange={handleEditorChange}
        content={input.value}
        init={editorConfig}
      />
      {(dispatched || touched) && error && <span className="form__form-group-error error-large">{error}</span>}
    </div>
  );
};

renderTinyMCEField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  dispatched: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderTinyMCEField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  dispatched: false,
};

export default renderTinyMCEField;
