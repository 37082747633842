/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import queryString from 'query-string';

const imageUploadHandler = (blobInfo, success, failure) => {
  const formData = new FormData();

  formData.append('image', blobInfo.blob());

  if (blobInfo.blob().type.includes('image')) {
    const imageSize = Math.trunc(blobInfo.blob().size / 1000);
    if (imageSize > 3000) {
      failure('Maximum size : 3M');
    }
    axios.post('/campaigns/image/add', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        if (response) {
          const json = JSON.parse(response.data);

          if (!json || typeof json.location !== 'string') {
            failure('Invalid JSON');
            return;
          }

          const location = process.env.REACT_APP_AWS_ROOT + process.env.REACT_APP_AWS_ATTACHMENTS_PATH + json.location;

          success(location);
        }
      })
      .catch(() => {
        failure('Something went wrong, please try again.');
      });
  } else {
    failure('The file is not a image, please try again with an image.');
  }
};

let content = null;
const editorConfig = {
  plugins: [
    'advlist autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste code help wordcount',
  ].join(' '),
  toolbar:
  // eslint-disable-next-line no-multi-str
    'undo redo | formatselect | bold italic backcolor | \
    alignleft aligncenter alignright alignjustify | \
    bullist numlist outdent indent | removeformat | help',
  block_formats: 'Paragraph=p;Heading 3=h3',
  height: 350,
  // without images_upload_url set, Upload tab won't show up
  images_upload_url: 'postAcceptor.php',
  images_upload_handler: imageUploadHandler,
  // statusbar: false,
  // body_class: 'editable-field-content',
  // paste_word_valid_elements: 'b,strong,i,em,h1,h2,h3,p,li,ul,ol,a',
  // paste_retain_style_properties: 'none',
  // paste_strip_class_attributes: 'none',
  // paste_remove_styles: true,
};

const renderTinyMCECampaignField = ({
  input, meta: { error }, dispatched = false, campaignId, id, showWarningMessage,
}) => {
  const [templates, setTemplates] = useState();
  const [touched, setTouched] = useState(false);
  const [saveData, setSaveData] = useState([]);
  const [templateId, setTemplateId] = useState(id);
  if (typeof templates === 'object' && templates !== null
    && 'content' in templates && templates.content !== null) {
    ({ content } = templates);
    setTemplates(null);
  }

  const replaceToCamelCase = (data, string) => {
    const regex = new RegExp(string, 'i');
    return data.replaceAll(
      data.match(regex)[0], string,
    );
  };

  const saveTemplateHandler = (data) => {
    if (data[0]) {
      const contentNoHtml = data[0].replace(/(<([^>]+)>)/ig, '');
      let title = document.getElementsByName(data[2].name.replace('content', 'title'))[0].value;
      const delay = document.getElementsByName(data[2].name.replace('content', 'delay'))[0]
        ? document.getElementsByName(data[2].name.replace('content', 'delay'))[0].value : 0;
      const varTemplates = [
        'investorFirstName',
        'investorLastName',
        'investorVertical',
        'investorCity',
        'investorFund',
        'startupName',
        'signature',
        'lastDeal',
        'trackingLink',
      ];

      // eslint-disable-next-line no-restricted-syntax
      for (const varTemplate of varTemplates) {
        if (data[0].toLowerCase().includes(varTemplate.toLowerCase()) !== false) {
          // eslint-disable-next-line no-param-reassign
          data[0] = replaceToCamelCase(data[0], varTemplate);
        }
        if (title.toLowerCase().includes(varTemplate.toLowerCase()) !== false) {
          // eslint-disable-next-line no-param-reassign
          title = replaceToCamelCase(title, varTemplate);
        }
      }

      if (contentNoHtml !== null && contentNoHtml !== '') {
        axios.post('campaigns/saveTemplate',
          queryString.stringify({
            content: data[0],
            title,
            id: data[3] ? data[3] : templateId,
            delay,
            position: data[2].name.replace(/\D+/g, ''),
            campaign_id: data[1],
          }))
          .then((response) => {
            if (response.data) {
              setTemplateId(response.data.templateId);
            }
          })
          .catch((response) => {
            if (response.data) {
              setTemplateId(response.data.templateId);
            }
          });
      }
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      saveTemplateHandler(saveData);
    }, 4000);

    return () => clearTimeout(delayDebounceFn);
  }, [saveData]);

  return (
    <div className="form__form-group-input-wrap">
      <Editor
        {...input}
        value={
          /* eslint-disable-next-line no-nested-ternary */
          content || (input.value !== ''
            ? input.value
            : null)
        }
        onBlur={(event) => {
          // setSaveData([event.target.getContent(), campaignId, input, id]);
          input.onChange(event.target.getContent());
          setTouched(true);
        }}
        // When setting onChange => input.onChange(event.target.getContent());
        // key enter is entering new line on top of editor
        onEditorChange={(actualContent) => {
          if (showWarningMessage) {
            showWarningMessage();
          }

          if (actualContent && actualContent !== '' && actualContent !== null) {
            setSaveData([actualContent, campaignId, input, id]);
            // input.onChange(actualContent);
          }
          setTouched(true);
        }}
        apiKey="fkjxk35pbnnm990x5wo3fakejxr9rrt5n9ojws99cpwrg76w"
        content={input.value}
        init={editorConfig}
      />
      {(dispatched || touched) && error && <span className="form__form-group-error error-large">{error}</span>}
    </div>
  );
};

renderTinyMCECampaignField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  dispatched: PropTypes.bool,
  campaignId: PropTypes.number,
  showWarningMessage: PropTypes.func,
  id: PropTypes.number,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderTinyMCECampaignField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  dispatched: false,
  campaignId: 0,
  id: 0,
  showWarningMessage: null,
};

export default renderTinyMCECampaignField;
