import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import {
  Field, getFormValues, reduxForm, change,
} from 'redux-form';
import { Prompt } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PlusIcon from 'mdi-react/PlusIcon';
import RemoveIcon from 'mdi-react/CloseIcon';

import NotificationSystem from 'rc-notification';
import ChevronRight from 'mdi-react/ChevronRightIcon';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import ModalTemplates from './ModalTemplates';
import validate from '../formValidate/formTwo';
import renderTextFieldBasic from '../../../shared/components/form/TextFieldBasic';
import renderTinyMCECampaignField from '../../../shared/components/form/TinyMCECampaignField';
import TourPopup from '../../../shared/components/tours/TourPopup';
import { CollapsibleNotification, BasicNotification } from '../../../shared/components/notifications/Notification';

let notificationRB = null;
let notificationRU = null;
let notificationTC = null;

const showNotification = ({ notification, position }, rtl) => {
  switch (position) {
    case 'right-bottom':
      notificationRB.notice({
        content: notification,
        duration: 6000,
        closable: true,
        style: { bottom: 0, top: 'initial', left: 0 },
        className: `${position} ${rtl}-support`,
      });
      break;
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 6000,
        closable: true,
        style: { top: 30, left: 'calc(100vw - 100%)' },
        className: `${position} ${rtl}-support`,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 500,
        closable: true,
        style: { top: 0, left: 0 },
        className: `${position} ${rtl}-support`,
      });
      break;
  }
};

class WizardFormTwo extends PureComponent {
  constructor() {
    super();
    this.state = {
      emails: 0,
      rank: 0,
      modalTemplates: false,
      triggerTour: false,
      closeTour: false,
      dispatched: false,
      // eslint-disable-next-line react/no-unused-state
      templates: {
        content: null,
        title: null,
      },
      warningDisplayed: false,
    };
    this.incrementEmail = this.incrementEmail.bind();
    this.decrementEmail = this.decrementEmail.bind();
  }

  componentDidMount() {
    const { valuesForm } = this.props;
    const user = JSON.parse(localStorage.getItem('user'));

    this.setState({
      // eslint-disable-next-line no-nested-ternary
      emails: valuesForm && typeof valuesForm !== typeof undefined
        && typeof valuesForm.templates !== typeof undefined
        && valuesForm.templates
        && valuesForm.templates.length > 0
        ? valuesForm.templates.length - 1
        : 0,
    });

    // If this user has no previous campaigns created, we guide him through this process.
    if (!user.campaigns) {
      this.setState({ triggerTour: true });
    }
    setTimeout(() => this.showTips(valuesForm), 1000);
  }

  componentWillUnmount() {
    if (notificationRU) {
      notificationRU.destroy();
    }
    if (notificationRB) {
      notificationRB.destroy();
    }
    if (notificationTC) {
      notificationTC.destroy();
    }
  }

  showTips = (valuesForm) => {
    let position = 140;
    if ((typeof valuesForm === 'object' && valuesForm.id === undefined) || typeof valuesForm !== 'object') {
      position = 200;
    }
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { bottom: position, top: 'initial' } }, n => notificationRB = n);
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationRU = n);
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationTC = n);

    this.showNotifError(
      (
        <div className="campaigns-tips">
          <p>
            You can use variables such as &nbsp;<strong>&#123;&#123;investorFirstName&#125;&#125;</strong> &nbsp;to
            mention the investor first name in your email.
          </p>
          <a
            /* eslint-disable-next-line max-len */
            href="https://angelspartners.crisp.help/en/article/automate-your-investor-outreach-campaigns-2efw09/?1603294616535"
            target="_blank"
            rel="noopener noreferrer"
            className="text-black-50"
          >
            <strong><u>Learn More &#8594;</u></strong>
          </a>
        </div>
      ), 'Use dynamic variables for your emails',
    );

    if ((typeof valuesForm === 'object' && valuesForm.id === undefined) || typeof valuesForm !== 'object') {
      this.showNotifWarning(
        (
          <div className="campaigns-tips">
            <p>
              Drop a tracking link to route investors to your startup profile like so:&nbsp;&nbsp;&nbsp;
              <strong>&#123;&#123;trackingLink&#125;&#125;</strong><br />
              i.e Here is our investor presentation &nbsp;&nbsp;<strong>&#123;&#123;trackingLink&#125;&#125;</strong>
            </p>
            <a
              /* eslint-disable-next-line max-len */
              href="https://angelspartners.crisp.help/en/article/automate-your-investor-outreach-campaigns-2efw09/?1603294616535"
              target="_blank"
              rel="noopener noreferrer"
              className="text-black-50"
            >
              <strong><u>Learn More &#8594;</u></strong>
            </a>
          </div>
        ), 'Track Investors Interest',
      );
      this.showNotifPrimary(
        (
          <div className="campaigns-tips">
            <p>
              To learn more about how to automate your campaigns&nbsp;
              <a
                /* eslint-disable-next-line max-len */
                href="https://angelspartners.crisp.help/en/article/automate-your-investor-outreach-campaigns-2efw09/?1603294616535"
                target="_blank"
                rel="noopener noreferrer"
                className="text-black-50"
              >
                <strong><u>checkout this resource</u></strong>
              </a>
              &nbsp;or&nbsp;
              <a
                /* eslint-disable-next-line max-len */
                href="https://youtu.be/sAL-kPH0xAM?t=93"
                target="_blank"
                rel="noopener noreferrer"
                className="text-black-50"
              >
                <strong><u>watch our video</u></strong>
              </a>
            </p>
          </div>
        ), 'Learn how to craft the perfect campaign in 5mins',
      );
    }
  }

  incrementEmail = () => {
    const { state } = this;
    const { emails, warningDisplayed } = state;
    const newTemplate = {
      content: null,
      title: null,
      delay: 8,
    };

    this.showWarningMessage(warningDisplayed);
    // eslint-disable-next-line react/no-unused-state
    this.setState({ templates: newTemplate, emails: emails + 1 });
    // eslint-disable-next-line react/destructuring-assignment,react/prop-types
    this.props.dispatch(change('wizard', `templates[${emails + 1}].delay`, 8));
    this.closeTour();
  }

  decrementEmail = (index) => {
    const { emails, warningDisplayed } = this.state;
    const { props } = this;
    const { valuesForm } = this.props;
    valuesForm.templates.splice(index, 1);

    this.showWarningMessage(warningDisplayed);
    props.change('valuesForm', undefined);
    if (emails > 0) {
      this.setState({ emails: emails - 1 });
    }
  }

  toggleModalTemplates = (rank) => {
    const { modalTemplates } = this.state;
    this.setState({ modalTemplates: !modalTemplates, rank });
    this.closeTour();
  };

  showNotifPrimary = (message, title) => showNotification({
    notification: <CollapsibleNotification
      color="primary"
      title={title}
      message={message}
    />,
    position: 'right-bottom',
  }, 'ltr');

  showNotifSuccess = (message, title) => showNotification({
    notification: <CollapsibleNotification
      color="success"
      title={title}
      message={message}
    />,
    position: 'right-bottom',
  }, 'ltr');

  showNotifWarning = (message, title) => showNotification({
    notification: <CollapsibleNotification
      color="warning"
      title={title}
      message={message}
    />,
    position: 'right-bottom',
  }, 'ltr');

  showNotifError = (message, title) => showNotification({
    notification: <CollapsibleNotification
      color="danger"
      title={title}
      message={message}
    />,
    position: 'right-bottom',
  }, 'ltr');

  // Whenever someone makes a change on a template in the step 2 of campaign edition
  showWarningMessage = (warningDisplayed, initialValues) => {
    if (!warningDisplayed && initialValues) {
      this.setState({ warningDisplayed: !warningDisplayed });
      showNotification({
        notification: <BasicNotification
          color="warning"
          message="To apply these changes to all the investors in this campaign,
          you need to submit your campaign on step 3 and update all investors"
        />,
        position: 'right-up',
      }, 'ltr');
    }
  };

  onTemplateSelected = (title, content) => {
    const { state } = this;
    const { rank } = state;
    // const { templates } = state.templates;

    const updatedTemplate = {
      title, content,
    };

    // eslint-disable-next-line react/no-unused-state
    this.setState({ templates: updatedTemplate, dispatched: true });

    // eslint-disable-next-line react/destructuring-assignment,react/prop-types
    this.props.dispatch(change('wizard', `templates[${rank}].title`, title));
    // eslint-disable-next-line react/prop-types,react/destructuring-assignment
    this.props.dispatch(change('wizard', `templates[${rank}].content`, content));
  }

  closeTour = () => {
    this.setState({ closeTour: true });
  };

  renderTemplateField = (initialValues, campaignId, dispatched, button, i, added, warningDisplayed) => (
    <div key={i} className={`mt-5 pt-5 mt-xl-3 wizard__form__email ${added && 'wizard__form__email-added'}`}>
      {button}
      <div className="form__form-group">
        <div className="d-flex flex-row align-items-center">
          <span className={`wizard__badge ${i % 2 === 0 ? 'wizard__badge-violet' : 'wizard__badge-green'}`}>
            Email 0{i + 1}
          </span>
          <Field
            name={`templates[${i}].title`}
            component={renderTextFieldBasic}
            type="text"
            placeholder="Subject"
          />
        </div>
      </div>
      <div className="form__form-group initial-field">
        <Field
          component={renderTinyMCECampaignField}
          showWarningMessage={() => this.showWarningMessage(warningDisplayed, initialValues)}
          dispatched={dispatched}
          campaignId={campaignId}
          id={initialValues && initialValues.templates && initialValues.templates[i]
            ? initialValues.templates[i].id : 0}
          name={`templates[${i}].content`}
        />
      </div>
      <div className="day__followup__field-wrapper d-flex flex-row align-items-center justify-content-between">
        {i > 0
        && (
          <div
            className="form__form-group-field day__followup__field form__form-group
                        mb-0 w-auto d-flex flex-row align-items-center"
          >
            <span className="form__form-group-label">Day till follow up</span>
            <Field
              name={`templates[${i}].delay`}
              component={renderTextFieldBasic}
              type="number"
              placeholder="in Days"
            />
          </div>
        )}
        <button
          type="button"
          onClick={() => this.toggleModalTemplates(i)}
          className="btn__template"
        >
          use a template
          <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.66675 1.5L7.66675 7.5L1.66675 13.5" stroke="#67BAEB" strokeWidth="2" fill="none" />
          </svg>
        </button>
      </div>
    </div>
  );

  shouldBlockNavigation = () => !!window.location.pathname.startsWith('/campaigns');

  render = () => {
    const {
      emails, modalTemplates, triggerTour, closeTour, dispatched, warningDisplayed,
    } = this.state;
    const {
      previousPage, handleSubmit, campaignId, initialValues,
    } = this.props;
    const emailsBlock = [];
    let i = 0;
    // eslint-disable-next-line no-plusplus
    while (++i <= emails) emailsBlock.push(i);

    return (
      <form className="form wizard__form wizard__form__campaign wizard__form__step-two" onSubmit={handleSubmit}>
        <Prompt
          when={emails > 0}
          message="You have unsaved changes, are you sure you want to leave?
                    To save you changes, submit your campaign on step 3."
        />
        <div className="wizard__form__header">
          <h3 className="wizard__title">Compose Emails in this campaign</h3>
          <p className="wizard__subtitle">
            Start drafting the first email of the campaign and add follow ups to increase your chances of getting
            responses.
          </p>
        </div>
        <div className="wizard__form__body">
          <div className="wizard__form__emails d-flex flex-row align-items-center flex-wrap">
            {this.renderTemplateField(initialValues, campaignId, dispatched, null, 0, null, warningDisplayed)}
            {/* eslint-disable-next-line no-shadow */}
            {emailsBlock.map((j) => {
              const button = (
                <button
                  type="button"
                  className="btn__remove__email"
                  onClick={() => this.decrementEmail(j)}
                >
                  <RemoveIcon size={24} />
                </button>
              );
              // eslint-disable-next-line max-len
              return this.renderTemplateField(initialValues, campaignId, dispatched, button, j, j > 1, warningDisplayed);
            })}
            <div className="wizard__form__followup">
              <button
                id="followup-button"
                type="button"
                className="wizard__form__followup-add d-flex flex-row align-items-center justify-content-center"
                onClick={() => this.incrementEmail()}
              >
                <PlusIcon /> Add Follow up
              </button>
            </div>
          </div>
          <ButtonToolbar className="form__button-toolbar wizard__toolbar d-flex flex-row align-items-center">
            <Button
              color="primary"
              type="button"
              className="previous btn btn-primary icon rounded float-left"
              onClick={previousPage}
            >
              <p>
                <ChevronLeftIcon />
                BACK
              </p>
            </Button>
            <button
              type="submit"
              className="next btn btn-primary icon rounded"
            >
              <p>
                Schedule Your Campaign
                <ChevronRight />
              </p>
            </button>
          </ButtonToolbar>
        </div>
        <ModalTemplates
          displayed={modalTemplates}
          toggleModal={() => this.toggleModalTemplates()}
          onTemplateSelected={(title, content) => this.onTemplateSelected(title, content)}
        />
        {triggerTour
          && (
            <TourPopup
              page="campaign2"
              closeTour={closeTour}
              triggerTour={triggerTour}
              disableFocusLock
            />
          )}
      </form>
    );
  }
}

WizardFormTwo.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    templates: PropTypes.object,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  valuesForm: PropTypes.object,
  campaignId: PropTypes.number,
};

WizardFormTwo.defaultProps = {
  valuesForm: null,
  initialValues: null,
  campaignId: 0,
};

// eslint-disable-next-line no-class-assign
WizardFormTwo = connect(state => ({
  valuesForm: getFormValues('wizard')(state),
}))(WizardFormTwo);


export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  validate,
})(WizardFormTwo);
