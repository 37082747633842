import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import NotificationSystem from 'rc-notification';
import { LinkNotification } from './notifications/Notification';
import ModalTrial from './modals/ModalTrial';

let notificationRU = null;

const showNotification = ({ notification, position }, rtl) => {
  notificationRU.notice({
    content: notification,
    duration: 30,
    closable: true,
    style: { top: 30, left: 'calc(100vw - 100%)' },
    className: `${position} ${rtl}-support`,
  });
};

class AccountWarnings extends Component {
  state = {
    user: JSON.parse(localStorage.getItem('user')),
    modalTrial: false,
  }

  static defaultProps = {
    showModalTrial: false,
    chat: false,
  };

  componentDidMount() {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationRU = n);

    // This showNotif is used to prevent notifications from being sent twice.
    const showNotif = JSON.parse(localStorage.getItem('showNotif'));
    if (showNotif === 1 || typeof showNotif === typeof undefined) {
      localStorage.setItem('showNotif', JSON.stringify(0));
      setTimeout(() => {
        this.showSteps();
        setTimeout(() => localStorage.setItem('showNotif', JSON.stringify(1)), 10000);
      }, 4000);
    } else {
      setTimeout(() => localStorage.setItem('showNotif', JSON.stringify(1)), 10000);
    }
    this.showTrialModal();
  }

  componentWillUnmount() {
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  toggleModalTrial = () => {
    const { modalTrial } = this.state;
    this.setState({ modalTrial: !modalTrial });
  };

  showTrialModal = () => {
    const { showModalTrial, chat } = this.props;

    if (chat && showModalTrial) {
      this.setState({ modalTrial: true });
    }
  }

  showSteps = () => {
    const { user } = this.state;
    let showWarnings = true;
    const url = window.location.href;

    if (user && !user.appSubmitted) {
      this.showNotifWarning('Finish Completing your Profile',
        'To contact investors, you must finish completing your profile. '
        + 'Go to <strong><u>My Company</u></strong> to complete your application.', '/myProfile/company');
      showWarnings = false;
    } else if (user && user.countSend > 0 && !user.mailboxConnected && user.isPayingCustomer) {
      this.showNotifWarning('Connect your Mailbox',
        'To contact investors and start sending emails, you must connect your mailbox to '
        + 'Angels Partners. <strong><u>Go to settings</u></strong> to get started.', '/myProfile/settings');
      showWarnings = false;
    } else if (user && user.pings <= 0 && !user.isCustomer && user.countSend > 0 && user.mailboxConnected) {
      this.showNotifWarning('Subscribe now!',
        'To contact investors, please subscribe to one of our subscription plans.'
        + '<br /><strong><u>Upgrade Now!</u></strong>',
        '/ext/pricing');
      showWarnings = false;
    } else if (user && user.isCustomer && !user.isPayingCustomer && user.campaigns > 0 && user.investorsAdded) {
      if (url.includes('/campaigns')) {
        this.setState({ modalTrial: true });
      } else {
        this.showNotifWarning(
          'Your Campaigns Are On Pause',
          'You cannot contact investors until the end of your trial. '
          + 'To start your campaign(s) immediately, click here.',
          '/campaigns',
        );
      }
      showWarnings = false;
    } else if (user && user.pings <= 0 && user.isPayingCustomer && user.countSend > 0 && user.mailboxConnected) {
      this.showNotifWarning('You do not have any pings left',
        'You should refill now if you want to resume your campaigns. '
        + 'All your campaigns are on pause at this moment and no emails will be sent.'
        + '<br /><strong><u>Upgrade Now</u></strong>', '/ext/pricing');
    } else if (
      user && user.pings < user.countSend && user.isPayingCustomer && user.mailboxConnected && user.pings < 50
    ) {
      this.showNotifWarning('Not enough pings left',
        `With ${user.pings} pings on your account you will not be able to send all the ${user.countSend} emails `
        + 'scheduled. Once your pings credit goes to 0, you will have to upgrade or wait for your subscription to '
        + 'renew.');
    }

    if (showWarnings) {
      setTimeout(() => this.displayWarningTrial(), 4000);
    }
  }

  displayWarningTrial = () => {
    const { user } = this.state;
    if (user && !user.isCustomer && user.investorsAdded) {
      this.showNotifWarning('Subscribe Now!',
        'You have pending emails in your campaigns waiting to be sent to investors. '
        + 'You must subscribe to one of our plans to start your campaigns.', '/ext/pricing');
    }
  }

  showNotifWarning = (title, message, link) => showNotification({
    notification: <LinkNotification
      color="warning"
      title={title}
      link={link}
      message={message}
    />,
    position: 'right-up',
  });


  render = () => {
    const { modalTrial, user } = this.state;
    const { chat } = this.props;
    let title; let content;

    if (chat) {
      title = 'This chat room is locked';
    } else if (user && user.isCustomer && !user.isPayingCustomer) {
      title = 'Your campaigns will start at the end of your trial period';
    } else {
      title = 'Your campaigns will start once you subscribe to a plan';
    }

    if (chat) {
      content = `You cannot contact investors until the end of your free trial.<br><br>
                 <strong><u>To engage with this investor immediately</u></strong>, you can end your trial
                 and start your paid subscription now.<br /><br>
                 Alternatively, you can wait until the end of your trial period.`;
    } else if (user && user.isCustomer && !user.isPayingCustomer) {
      content = `You cannot contact investors until the end of your free trial.<br><br>
                 <strong><u>To start your campaign(s) immediately</u></strong>, you can end your trial 
                 and start your paid subscription now.<br /><br>
                 Alternatively, you can wait until the end of your trial period.`;
    } else {
      content = `You must subscribe to one of our paid plan if you want to contact investors.<br><br>
                 <strong><u>To start your campaign(s) immediately</u></strong>, you can start your paid subscription
                 using the link below.<br /><br>
                 Alternatively, you can wait until the end of your trial period.`;
    }

    return (
      <ModalTrial
        color="primary"
        title={title}
        colored
        displayed={modalTrial}
        toggleModal={() => this.toggleModalTrial()}
        pings
        message={content}
        chat={chat}
      />
    );
  }
}

AccountWarnings.propTypes = {
  showModalTrial: PropTypes.bool,
  chat: PropTypes.bool,
};

export default withRouter(AccountWarnings);
