import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button, Col, Container, Row,
} from 'reactstrap';
import axios from 'axios';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import queryString from 'query-string';
import NotificationSystem from 'rc-notification';
import MailCheckIcon from 'mdi-react/EmailCheckOutlineIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import MailMultipleIcon from 'mdi-react/EmailMultipleOutlineIcon';
import ProfileMain from './components/ProfileMain';
import ProfileContent from './components/ProfileContent';
import {
  BasicNotification, FullWideNotification, LinkNotification,
} from '../../shared/components/notifications/Notification';
import Modal from '../../shared/components/modals/Modal';
import ModalFundSelectInvestors from '../../shared/components/modals/ModalFundSelectInvestors';
import ModalCampaigns from '../../shared/components/modals/ModalSingleInvestorCampaign';
import ModalReport from '../../shared/components/modals/ModalReport';

let notificationTC = null;
let notificationRU = null;

const showNotification = ({ notification, position }, rtl) => {
  switch (position) {
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 30,
        closable: true,
        style: { top: 30, left: 'calc(100vw - 100%)' },
        className: `${position} ${rtl}-support`,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 15,
        closable: true,
        style: { top: 0, left: 0 },
        className: `${position} ${rtl}-support`,
      });
      break;
  }
};

class ExternalInvestorProfile extends PureComponent {
  state = {
    investor: {},
    similarInvestors: [],
    loading: true,
    loaded: false,
    modal: false,
    modalCampaigns: false,
    modalInvestors: false,
    modalReport: false,
    user: JSON.parse(localStorage.getItem('user')),
    investorsContactInfo: [],
    extInvestorContactInfo: null,
    openNextModal: null,
  };

  componentDidMount = () => {
    const { props } = this;
    this.init(props.match.params.id);

    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationTC = n);
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationRU = n);
  };

  componentWillReceiveProps(nextProps) {
    const { state } = this;
    const prevStateId = parseInt(state.investor.id, 10);
    // eslint-disable-next-line react/prop-types
    const nextPropsId = parseInt(nextProps.match.params.id, 10);
    if (!Number.isNaN(prevStateId)
      && !Number.isNaN(nextPropsId)
      && nextPropsId !== prevStateId) {
      this.init(nextPropsId);
    }
    return null;
  }

  componentWillUnmount() {
    if (notificationTC) {
      notificationTC.destroy();
    }
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  init = (id) => {
    axios.get(`/investors-db/profile/${id}`)
      .then((response) => {
        this.setState({ investor: response.data, loading: false });
        setTimeout(() => this.setState({ loaded: true }), 500);
        this.getSimilarInvestors(id);
      }).catch((error) => {
        if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
          this.showNotif(error.response.data.message);
        } else {
          this.showNotif('Sorry, an error occurred, please try again.');
        }
      });
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationTC = n);

    window.addEventListener('popstate', () => {
      // The popstate event is fired each time when the current history entry changes.
      sessionStorage.setItem('scrollActiveExt', true);
    }, false);
  };

  getSimilarInvestors = (id) => {
    axios.get(`investors-db/similarInvestors/${id}`)
      .then((res) => {
        this.setState({ similarInvestors: res.data });
      });
  }

  updateInvestor = (id, adding, favorite, inCampaign) => {
    // Set up Loading
    const { investor } = this.state;

    // Sort out Favorite and Pinged
    // eslint-disable-next-line no-param-reassign,prefer-destructuring
    if (favorite === null) favorite = investor.favorite;

    // Sort out Favorite and Pinged
    // eslint-disable-next-line no-param-reassign,prefer-destructuring
    if (inCampaign === null) inCampaign = investor.inCampaign;

    // Update Investor
    const updatedInvestor = {
      ...investor, adding, favorite, inCampaign,
    };

    // Update the State
    this.setState({ investor: updatedInvestor });

    return true;
  };

  showModal = () => {
    const { modal } = this.state;
    if (modal) {
      // We're adding this line in case the user clicks
      // on a different ping button in within the 10 seconds
      this.setState({ modal: false }, () => {
        this.setState({ modal: true });
      });
    } else {
      this.setState({ modal: true });
    }
    setTimeout(() => {
      // Resets the state of the modal
      this.setState({ modal: false });
    }, 10000);
  };


  toggleModalInvestor = () => {
    const { modalInvestors } = this.state;
    this.setState({ modalInvestors: !modalInvestors });
  };

  toggleModalPing = () => {
    const { modalPing } = this.state;
    this.setState({ modalPing: !modalPing });
  };

  toggleModalCampaigns = () => {
    const { modalCampaigns } = this.state;
    this.setState({ modalCampaigns: !modalCampaigns });
  };

  toggleModalReport = () => {
    const { modalReport } = this.state;
    this.setState({ modalReport: !modalReport });
  };

  showNotif = message => showNotification({
    notification: <FullWideNotification
      color="danger"
      message={message}
    />,
    position: 'full',
  }, 'ltr');

  showNotifSuccess = message => showNotification({
    notification: <BasicNotification
      color="primary"
      title="Success!"
      message={message}
    />,
    position: 'right-up',
  });

  showNotifWarning = (message, link) => showNotification({
    notification: <LinkNotification
      color="warning"
      title="Warning!"
      link={link}
      message={message}
    />,
    position: 'right-up',
  });

  goBack = () => {
    sessionStorage.setItem('scrollActiveExt', true);
    // eslint-disable-next-line react/prop-types
    const { history } = this.props;
    history.goBack();
  };

  renderMailButton = () => {
    const { investor } = this.state;

    if (investor.inCampaign && investor.contacted) {
      return (
        <Button
          className="icon icon--right rounded extinvestor__mailed-btn"
          color="warning"
          onClick={() => this.clickAddToCampaign(investor, null)}
          outline
        >
          <p>Contacted <MailCheckIcon /></p>
        </Button>
      );
    }
    if (investor.inCampaign && !investor.contacted) {
      return (
        <Button
          className="icon icon--right rounded extinvestor__mailed-btn"
          color="warning"
          onClick={() => this.clickAddToCampaign(investor, null)}
          outline
        >
          <p>In Campaign <MailMultipleIcon /></p>
        </Button>
      );
    }
    if (investor.email || (investor.connectedInvestors && investor.connectedInvestors.length > 0)) {
      return (
        <Button
          id={`Tooltip2Left${investor.id}`}
          className="icon icon--right rounded extinvestor__mail-btn icon--blue"
          color="primary"
          onMouseDown={e => this.clickAddToCampaign(investor, null, e)}
          onKeyUp={(e) => { if (e.keyCode === 13 || e.keyCode === 32) { this.clickAddToCampaign(investor, null, e); } }}
        >
          <p>Contact <ChevronRightIcon /></p>
        </Button>
      );
    }
    return null;
  };

  clickAddToCampaign = (investor, email, e) => {
    if (e) { e.preventDefault(); }
    if (investor.contactId) {
      this.setState({
        modalCampaigns: true,
        extInvestorContactInfo:
          {
            contactId: investor.contactId,
            fullName: investor.fullName,
            picture: investor.picture,
            investorId: investor.id,
            email: email || investor.email,
            inCampaign: investor.inCampaign,
            campaignId: investor.campaignId,
            contacted: investor.contacted,
            contactedOn: investor.contactedOn,
          },
      });
    } else {
      this.setState({
        modalInvestors: true,
        openNextModal: 'modalCampaigns',
        investorsContactInfo: investor.connectedInvestors,
      });
    }
  };

  // This function updates le list of contact info for firms
  updateContactInfo = (selectedInvestor, idContact = null) => {
    // Set up Loading
    const { state } = this;
    const { openNextModal, investor } = state;

    // Retrieve the contacts of this investor
    const connectedInvestors = [...investor.connectedInvestors];

    const contact = connectedInvestors.find(i => i.contactId === idContact);

    // Update the State
    if (openNextModal === 'modalCampaigns') {
      this.setState({ modalCampaigns: true }, () => this.clickAddToCampaign(contact, contact.email));
    }
  };

  clickReportInvestor = (id, contactId, email, e) => {
    if (e) { e.preventDefault(); }

    const { investor } = this.state;

    if (contactId) {
      this.setState(
        {
          extInvestorContactInfo:
              {
                contactId,
                fullName: investor.fullName,
                investorId: id,
                email: email || investor.email,
                inCampaign: investor.inCampaign,
                campaignName: investor.campaignName,
                campaignId: investor.campaignId,
                contacted: investor.contacted,
                contactedOn: investor.contactedOn,
              },
        }, () => this.setState({ modalReport: true }),
      );
    } else {
      this.setState({
        modalInvestors: true,
        openNextModal: 'modalReport',
        investorsContactInfo: investor.connectedInvestors,
      });
    }
  };

  addFavorites = (e) => {
    if (e) { e.preventDefault(); }
    const { investor } = this.state;
    const { id } = investor;

    // Set up Loading
    this.updateInvestor(id, true, false, null);

    // Post the Ping.
    axios.post('/investors-db/favorite/add', queryString.stringify({ id }))
      .then((response) => {
        if (response) {
          this.updateInvestor(id, false, true, null);
        }
      })
      .catch((error) => {
        if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
          this.showNotif(error.response.data.message);
        } else {
          this.showNotif('Sorry, an error occurred, please try again.');
        }
        this.updateInvestor(id, false, false, null);
      });
  };

  removeFavorites = (e) => {
    if (e) { e.preventDefault(); }
    const { investor } = this.state;
    const { id } = investor;

    // Set up Loading
    this.updateInvestor(id, true, true, null);

    // Post the Ping.
    axios.post('/investors-db/favorite/remove', queryString.stringify({ id }))
      .then((response) => {
        if (response) {
          this.updateInvestor(id, false, false, null);
        }
      })
      .catch((error) => {
        if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
          this.showNotif(error.response.data.message);
        } else {
          this.showNotif('Sorry, an error occurred, please try again.');
        }
        this.updateInvestor(id, false, true, null);
      });
  };

  render = () => {
    const {
      investor, loading, loaded, modal, user,
      modalCampaigns, modalInvestors, modalReport,
      investorsContactInfo, extInvestorContactInfo, similarInvestors,
    } = this.state;

    return (
      <Container>
        {!loaded
          ? (
            <div className={`load${loading ? '' : ' loaded'} inload`}>
              <div className="load__icon-wrap">
                <svg className="load__icon">
                  <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                </svg>
              </div>
            </div>
          )
          : (
            <div className="profile">
              <div className="background">&nbsp;</div>
              <Row>
                <Col xs={12} lg={1}>
                  <span
                    role="presentation"
                    onMouseDown={e => this.goBack(e)}
                    onKeyUp={(e) => { if (e.keyCode === 13 || e.keyCode === 32) { this.goBack(e); } }}
                    className="back-btn text-white"
                  >
                    <ChevronLeftIcon /> BACK
                  </span>
                </Col>
                <Col xs={12} lg={11}>
                  <ProfileMain
                    fullName={investor.fullName}
                    linkedin={investor.linkedin}
                    facebook={investor.facebook}
                    google={investor.google}
                    angellist={investor.angellist}
                    website={investor.website}
                    crunchbase={investor.crunchbase}
                    twitter={investor.twitter}
                    companyRole={investor.companyRole}
                    company={investor.company}
                    locationCity={investor.locationCity}
                    locationCountry={investor.locationCountry}
                    picture={investor.picture}
                    fundId={investor.fundId}
                    region={investor.region}
                    visits={investor.visits}
                    sent={investor.sent}
                    opened={investor.opened}
                    registered={investor.registered}
                    adding={investor.adding}
                    favorite={investor.favorite}
                    renderMailButton={this.renderMailButton()}
                    addFavorites={e => this.addFavorites(e)}
                    removeFavorites={e => this.removeFavorites(e)}
                    clickReport={e => this.clickReportInvestor(investor.id, investor.contactId, null, e)}
                  />
                </Col>
                <Col xs={12}>
                  <ProfileContent
                    accepted={investor.accepted}
                    categories={investor.categories}
                    currency={investor.currency}
                    company={investor.company}
                    description={investor.description}
                    expertise={investor.expertise}
                    fullName={investor.fullName}
                    investmentMin={investor.investmentMin}
                    investmentMax={investor.investmentMax}
                    investmentSweetSpot={investor.investmentSweetSpot}
                    investmentCountries={investor.investmentCountries}
                    rounds={investor.rounds}
                    stages={investor.stages}
                    twitter={investor.twitter}
                    specialities={investor.specialities}
                    linkedinDescription={investor.linkedinDescription}
                    descriptionVC={investor.descriptionVC}
                    connectedInvestors={investor.connectedInvestors}
                    coInvestors={investor.coInvestors}
                    experience={investor.experience}
                    aum={investor.aum}
                    investorType={investor.investorType}
                    fundType={investor.fundType}
                    isFirm={investor.isFirm}
                    similarInvestors={similarInvestors}
                  />
                </Col>
              </Row>
            </div>
          )
        }
        <Modal
          color="primary"
          title="Out of Pings!"
          colored
          displayed={modal}
          pings
          message={`You do not have enough Pings left to contact this investor.<br><br>
           To send your deck to this investor's mailbox and pin your profile at the top
           of their dashboard, you need to buy more Pings.<br><br>
           To do so, please visit our <a href='${process.env.REACT_APP_DOMAIN_URL}/pricing'>Pricing Page</a>.`}
        />
        <ModalFundSelectInvestors
          displayed={modalInvestors}
          user={user}
          investorId={investor.id}
          investorsContactInfo={investorsContactInfo}
          fundId={investor.isFirm ? investor.id : investor.fundId}
          fundName={investor.isFirm ? investor.fullName : investor.company}
          fundPicture={investor.isFirm ? investor.picture : null}
          updateContactInfo={
            contactId => this.updateContactInfo(investor.id, contactId)
          }
          toggleModal={() => this.toggleModalInvestor()}
        />
        <ModalCampaigns
          updateListOfInvestors={
            investorId => this.updateInvestor(investorId, false, null, true)
          }
          investorContactInfo={extInvestorContactInfo}
          displayed={modalCampaigns}
          toggleModal={() => this.toggleModalCampaigns()}
          showNotif={message => this.showNotif(message)}
          showNotifSuccess={name => this.showNotifSuccess(name)}
          showNotifWarning={(message, link) => this.showNotifWarning(message, link)}
          user={user}
        />
        <ModalReport
          investorContactInfo={extInvestorContactInfo}
          displayed={modalReport}
          toggleModal={() => this.toggleModalReport()}
          showNotifSuccess={name => this.showNotifSuccess(name)}
          showNotifError={message => this.showNotif(message)}
        />
      </Container>
    );
  }
}

export default withRouter(ExternalInvestorProfile);
