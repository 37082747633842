import {
  change, Field, getFormValues, reduxForm,
} from 'redux-form';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import { connect } from 'react-redux';
import validate from '../../../MyStartupProfile/formValidate/info';

const renderTextField = ({
  input, label, meta: { touched, error }, children, type, placeholder, multiline,
}) => (
  <div className="form__form-group-input-wrap">
    <TextField
      className="material-form__field template-content"
      label={label}
      type={type}
      error={touched && error}
      helperText={touched && error} // This will show the error message
      value={input.value}
      placeholder={placeholder}
      multiline={multiline}
      rows={2}
      rowsMax={5}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    >
      {children}
    </TextField>
  </div>
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  children: PropTypes.arrayOf(PropTypes.element),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
};

renderTextField.defaultProps = {
  meta: null,
  label: '',
  children: [],
  type: 'text',
  placeholder: '',
  multiline: false,
};

class MessagesForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
      templates: PropTypes.arrayOf(PropTypes.shape({
        content: PropTypes.string,
        delay: PropTypes.number,
      })),
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    connectionsToContact: PropTypes.arrayOf(PropTypes.number).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    valuesForm: PropTypes.object,
    updateTemplates: PropTypes.func.isRequired,
  }

  static defaultProps = {
    valuesForm: null,
  };

  componentDidUpdate(prevProps) {
    const {
      initialValues, dispatch, valuesForm, updateTemplates,
    } = this.props;

    if (prevProps.initialValues !== initialValues) {
      for (let i = 0; i < initialValues.templates.length; i += 1) {
        // eslint-disable-next-line react/prop-types,react/destructuring-assignment
        dispatch(change('select_connections_form', `templates[${i}].content`, initialValues.templates[i].content));
      }
    }

    if (prevProps.valuesForm !== valuesForm) {
      updateTemplates(valuesForm);
    }
  }

  renderMessageFields = (initialValues, i, added) => (
    <div key={i} className={`mt-4 pt-4 mt-xl-3 wizard__form__email linkedin ${added && 'wizard__form__email-added'}`}>
      <div className="form__form-group">
        <div className="d-flex flex-row align-items-center">
          <span className={`wizard__badge ${i % 2 === 0 ? 'wizard__badge-violet' : 'wizard__badge-green'}`}>
            Message 0{i + 1}
          </span>
        </div>
      </div>
      <div className="form__form-group initial-field">
        <Field
          component={renderTextField}
          id={initialValues && initialValues.templates && initialValues.templates[i]
            ? initialValues.templates[i].id : 0}
          name={`templates[${i}].content`}
          multiline
          i={i}
        />
      </div>
    </div>
  );

  render = () => {
    const { initialValues, handleSubmit, connectionsToContact } = this.props;

    return (
      // eslint-disable-next-line max-len
      <form className="form material-form w-100 warm-intro" onSubmit={handleSubmit}>
        {connectionsToContact.length > 0
        && (
          <button
            type="submit"
            className="btn btn-primary icon rounded ml-auto"
          >
            <p>
            Ask {connectionsToContact.length} Intro{connectionsToContact.length > 1 && 's'} <PlusCircleOutlineIcon />
            </p>
          </button>
        )}
        {initialValues
          ? initialValues.templates.map((value, key) => this.renderMessageFields(value, key, key > 0))
          : null
        }
      </form>
    );
  }
}

// eslint-disable-next-line no-class-assign
MessagesForm = connect(state => ({
  valuesForm: getFormValues('select_connections_form')(state),
}))(MessagesForm);

export default reduxForm({
  form: 'select_connections_form', // a unique identifier for this form
  validate,
})(MessagesForm);
