import React, { PureComponent } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import validate from './formValidate/formBlankEmail';
import renderTextFieldBasic from '../../form/TextFieldBasic';
import Alert from '../../alerts/Alert';

class BlankEmailForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
      templates: PropTypes.object,
    }),
    investorContactInfo: PropTypes.shape({
      contactId: PropTypes.number,
      fullName: PropTypes.string,
      investorId: PropTypes.number,
      email: PropTypes.bool,
    }).isRequired,
    activeTab: PropTypes.number.isRequired,
    formError: PropTypes.string,
    user: PropTypes.shape({
      mailboxConnected: PropTypes.bool,
      completion: PropTypes.number,
    }).isRequired,
  };

  static defaultProps = {
    initialValues: null,
    formError: null,
  }

  editorConfig = {
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount',
    ].join(' '),
    toolbar:
    // eslint-disable-next-line no-multi-str
      'undo redo | formatselect | bold italic backcolor | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | removeformat | help',
    block_formats: 'Paragraph=p;Heading 3=h3',
    height: 350,
  }

  renderTinyMCE = (field) => {
    const { input } = field;
    // eslint-disable-next-line react/prop-types
    const { touched, error } = field.meta;
    // const { templates } = this.state;

    return (
      <div className="form__form-group-input-wrap">
        <Editor
          {...input}
          value={input.value !== '' ? input.value : null}
          apiKey="fkjxk35pbnnm990x5wo3fakejxr9rrt5n9ojws99cpwrg76w"
          onBlur={(event) => { field.input.onChange(event.target.getContent()); }}
          init={this.editorConfig}
        />
        {touched && error && <span className="form__form-group-error">{error}</span>}
      </div>
    );
  }

  render() {
    const {
      handleSubmit, onSubmit, investorContactInfo, formError, user,
    } = this.props;

    return (
      <form
        className="form form--horizontal modal-campaigns"
        onSubmit={handleSubmit(onSubmit.bind(this))}
      >
        {!user.appSubmitted
          ? (
            <Alert color="warning" className="alert--colored mt-4 ml-lg-5">
              You can schedule this email now but your message will not be sent until you have completed your profile
            </Alert>
          ) : !user.mailboxConnected && (
            <Alert color="warning" className="alert--colored mt-4 ml-lg-5">
              You can schedule this email now but your message will not be sent until you have connected your mailbox.
            </Alert>
          )}

        {formError
        && (
          <Alert color="danger" className="alert--colored ml-4 ml-lg-5">
            <p><span className="bold-text">Error!</span> {formError}
            </p>
          </Alert>
        )}
        <div className="focus-form mt-5 w-100 pr-0 pl-0 pr-lg-5 pl-lg-5">
          <div className="form__form-group light">
            <Field
              name="title"
              component={renderTextFieldBasic}
              type="text"
              placeholder="Subject"
            />
          </div>
          <div className="form__form-group">
            <Field
              name="content"
              component={this.renderTinyMCE}
            />
          </div>
        </div>
        {investorContactInfo && investorContactInfo.email && (
          <Button className="submit-campaign rounded icon icon--right icon--blue" color="primary" type="submit">
            Send Email
            <ChevronRightIcon />
          </Button>
        )}
      </form>
    );
  }
}

export default reduxForm({
  form: 'modalCampaigns', //         <------ same form name
  destroyOnUnmount: true, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(BlankEmailForm);
